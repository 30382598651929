import createReactComponent from '../createReactComponent';
export default createReactComponent('package-off', 'IconPackageOff', [
  [
    'path',
    {
      d: 'M8.812 4.793l3.188 -1.793l8 4.5v8.5m-2.282 1.784l-5.718 3.216l-8 -4.5v-9l2.223 -1.25',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.543 10.57l5.457 -3.07', key: 'svg-1' }],
  ['path', { d: 'M12 12v9', key: 'svg-2' }],
  ['path', { d: 'M12 12l-8 -4.5', key: 'svg-3' }],
  [
    'path',
    { d: 'M16 5.25l-4.35 2.447m-2.564 1.442l-1.086 .611', key: 'svg-4' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
